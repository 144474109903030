<template>
  <v-container fluid class="tb-layout-browse">
    <v-card class="pa-2">
      <ABreadcrumb :items="breadcrumbItems" />

      <transition name="fade">
        <v-alert
          dense
          border="left"
          type="warning"
          class="text-pre-wrap"
          v-if="alertError.length > 0"
          ><span
            class="d-block"
            v-for="(error, index) in alertError"
            :key="index"
            >{{ error }}</span
          ></v-alert
        >
      </transition>

      <BreadHeader
        :title="`${this.service.key.toUpperCase()} taxPOD ${model.name.plural}`"
      >
      </BreadHeader>

      <v-row class="tb-filter-section d-none1">
        <v-row no-gutters>
          <v-col md="4">
            <div class="tb-left-second-section">
              <div>
                <h5
                  v-if="switchToAdvanceFilter == true"
                  @click="switchToAdvanceFilter = false"
                  style="cursor: pointer"
                >
                  Switch to Normal Filter
                </h5>
                <h5
                  v-if="switchToAdvanceFilter == true"
                  @click="switchToAdvanceFilter = true"
                  style="cursor: pointer"
                >
                  Switch to Advance Filter
                </h5>
              </div>
              <div>
                <h4 v-on:click="isShow = !isShow" style="float: left">
                  Filter
                  <v-icon left> mdi mdi-menu-down </v-icon>
                </h4>
                <span v-if="switchToAdvanceFilter == false">
                  {{ conditions.length }} Filter Applied
                </span>
              </div>
            </div>
          </v-col>
          <v-col md="8">
            <!-- include column selection compnent -->
            <BreadTabulatorSelectColumn
              ref="selectColumn"
              :model="model"
              @applyColumnSelection="applyColumnSelection"
            >
            </BreadTabulatorSelectColumn>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2">
          <v-col md="12">
            <div class="tb-normal-filter" v-show="isShow">
              <div
                class="tb-filter-area"
                v-show="switchToAdvanceFilter == false"
              >
                <BreadTabulatorFilterable
                  ref="filter"
                  :model="model"
                  :setConditions="setConditions"
                >
                </BreadTabulatorFilterable>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-row>

      <!-- defult search area-->
      <div class="tb-search-area">
        <v-row>
          <v-col md="6">
            <BreadTabulatorSearchable
              ref="search"
              :model="model"
              :setConditions="setConditions"
            >
            </BreadTabulatorSearchable>
            <div>
              <span
                id="record-count"
                style="float: left; font-size: 12px"
              ></span>
            </div>
          </v-col>

          <v-col md="6" class="d-flex justify-end">
            <v-btn-toggle
              dense
              multiple
              class="mr-1"
              v-if="hrdcData.isFinanceApAdminRoles && !btnDisabled"
            >
              <ApApplicationSelection
                :apApplicationSelectionDialog="apApplicationSelectionDialog"
                :apiUrl="this.apiGetBrowseUrl()"
                @showRefundClientForm="showRefundClientForm"
                @toggleSelectionDialog="toggleSelectionDialog"
              ></ApApplicationSelection>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </div>

      <BreadTabulator
        ref="tabulatorDataTable"
        layout="fitColumns"
        :autoColumns="false"
        :paginationSize="paginationSize"
        :paginationSizeSelector="paginationSizeSelector"
        :apiUrl="apiGetBrowseUrl()"
        :columns="columns"
        :modelKey="modelKey"
        :columnSelectionDisabledCount="columnSelectionDisabledCount"
        @getCurrentPageSize="getCurrentPageSize"
        @getCurrentSort="getCurrentSort"
        @getCurrentColumnWidth="getCurrentColumnWidth"
        @getCurrentPageNumber="getCurrentPageNumber"
      >
      </BreadTabulator>
    </v-card>
    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
    <ApRejectButton
      :rejectDialog="rejectDialog"
      :errorField="errorField"
      :formData="formData"
      @closeRejectDialog="closeRejectDialog()"
      @rejectApplicationToAR="rejectApplicationToAR"
    ></ApRejectButton>
  </v-container>
</template>
<script>
import Fuse from "fuse.js";
import axios from "axios";
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import { json2excel } from "js2excel";
import Service from "@/objects/service";
import HrdcData from "@/services/hrdc/objects/globalData";
import hrdcFunction from "@/services/hrdc/objects/hrdcFunction";
import ApApplicationSelection from "@/services/hrdc/views/finance_ap_submit_refund_to_cimb/ApApplicationSelection";
import ApRejectButton from "@/services/hrdc/views/finance_ap_submit_refund_to_cimb/ApRejectButton";

export default {
  mixins: [hrdcFunction],
  components: {
    ApApplicationSelection,
    ApRejectButton,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      hrdc: (state) => state.hrdc.data,
    }),
  },
  props: ["params"],
  data: () => ({
    api: new Api(),
    apiUpdateApSubmitRefundToCimb: new Api(),
    apiRejectApplicationToAR: new Api(),
    conditions: [],
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    listAllViews: [],
    btnDisabled: false,
    columns: [],
    breadcrumbItems: [
      {
        text: "Dashboard",
        to: {
          name: "PageConsoleDashboard",
        },
        exact: true,
      },
      {
        text: "Services",
        to: {
          name: "PageServiceBrowse",
        },
        exact: true,
      },
      {
        text: "HRDC",
        to: { name: "PageServiceDashboard", params: { serviceKey: "hrdc" } },
        exact: true,
      },
      {
        text: "Applications",
        to: {
          name: "HrdcApplication",
          params: { serviceKey: "hrdc" },
          query: {
            viewId: "all",
            tabIndex: 0,
          },
        },
        exact: true,
      },
    ],
    paginationSize: 10,
    paginationSizeSelector: [10, 50, 100, 200, 500],
    modelKey: "",
    cColumnSort: [],
    displayRowCount: 10,
    selectedCustomColums: [],
    selectedFilterConditions: [],
    switchToAdvanceFilter: false,
    isShow: false,
    allResizeColumns: [],
    columnSelectionDisabledCount: 3,
    currentPageNo: 1,
    errorField: {
      rejectReason: null,
    },
    formData: {
      applicationUuid: null,
      rejectReason: null,
    },
    alertError: [],
    loadingDialog: false,
    rejectDialog: false,
    apApplicationSelectionDialog: false,
  }),
  watch: {
    //
  },
  async created() {
    this.model.getByKey("hrdcRefundEmailApToCimb");
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    this.modelKey = "hrdcRefundEmailApToCimb";
    this.columns = this.$_.filter(
      this.model.browse.table.headers,
      (headers) => {
        return headers;
      }
    );

    var columnIndex = 0;
    for (var column of this.columns) {
      // Add click company cell direct to view details.
      if (column.field == "company") {
        this.columns[columnIndex].cellClick = (_, cell) => {
          const {
            applicationUuid,
            stageId,
            firstNameAccEnrollment,
            lastNameAccEnrollment,
            contactNoAccEnrollment,
            emailAccEnrollment,
          } = cell.getData();

          this.$store.commit("assignHrdcData", {
            applicationUuid,
            stageId,
            firstNameAccEnrollment,
            lastNameAccEnrollment,
            contactNoAccEnrollment,
            emailAccEnrollment,
            viewId: this.$route.query.viewId,
          });

          this.$router.push({
            name: "ViewHrdcApplication",
            query: {
              currentPageNo: this.currentPageNo,
              _ausk: applicationUuid,
            },
          });
        };
      }

      columnIndex++;
    }

    this.columns.push({
      title: "Action",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: "100",
      formatter: (cell, _, onRendered) => {
        const data = cell.getData();
        const previewAtchUrl = `${process.env.VUE_APP_SERVICE_URL_HRDC}/v1/en/console/application/get-attachment-via-stage`;
        const streamUrl = `${process.env.VUE_APP_SERVICE_URL_HRDC}/v1/en/console/application/azure-download-or-preview-application-attachment`;

        let btn = `
          <button type="button" class="view-pdf text-light v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small" style="background-color: ${this.hrdcData.HRDC_BLUE_THEME_COLOR}">
            View PDF
          </button>
        `;

        // Only finance-ap roles can reject application to AR stages.
        if (this.hrdcData.isFinanceApAdminRoles) {
          btn += `
            <button type="button" class="reject-btn text-light v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small red lighten-1">
              Reject
            </button>
          `;
        }

        onRendered(() => {
          cell
            .getElement()
            .querySelector(".view-pdf")
            .addEventListener("click", async (e) => {
              this.loadingDialog = !this.loadingDialog;

              e.stopPropagation(); // Prevent row click event

              // Get fileUrl by applicationUuid & preview.
              await axios
                .post(
                  previewAtchUrl,
                  {
                    applicationNo: data.applicationUuid,
                    stageId: this.hrdcData.stagePriority.refundEmailArToAp,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${this.$store.state.auth.data.token}`,
                    },
                  }
                )
                .then(async (response) => {
                  if (!response.status) {
                    throw new Error(response.data);
                  }

                  const { data } = response.data;
                  const compiledPdfObj = data[0];
                  const fileUrl = compiledPdfObj.fileUrl;

                  await axios
                    .get(streamUrl, {
                      responseType: "blob",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      params: {
                        filePath: fileUrl,
                      },
                    })
                    .then((response) => {
                      const blobUrl = URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                      );

                      window.open(blobUrl, "_blank");

                      this.loadingDialog = !this.loadingDialog;
                    })
                    .catch((error) => {
                      if ((error.response.status == 404) | true) {
                        this.$store.dispatch("showMessage", {
                          message:
                            "Failed to Preview/Download attachment, kindly contact administrator",
                          messageType: "error",
                          timeout: 2000,
                        });
                      }
                      this.loadingDialog = !this.loadingDialog;
                    });
                })
                .catch((err) => {
                  console.log(err);
                  this.loadingDialog = !this.loadingDialog;
                  this.$store.dispatch("showMessage", {
                    message:
                      "Failed to Preview/Download attachment, kindly contact administrator",
                    messageType: "error",
                    timeout: 2000,
                  });
                  return false;
                });
            });

          if (this.hrdcData.isFinanceApAdminRoles) {
            cell
              .getElement()
              .querySelector(".reject-btn")
              .addEventListener("click", async () => {
                this.rejectDialog = true;
                this.formData.applicationUuid = cell.getData().applicationUuid;
              });
          }
        });

        return btn;
      },
      htmlOutput: true,
    });

    this.hrdcData.rolesAssignation(this.auth.roles);

    this.stages = await this.hrdcData.allStages;

    this.banks = await fetch(
      `${this.$service[this.service.key]}/v1/en/console/bank/get-banks`
    )
      .then((res) => res.json())
      .then((result) => {
        this.banks = result.data;
        return result.data;
      });

    this.hrdcData.rolesAssignation(this.auth.roles);

    if (!this.hrdcData.isFinanceApAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }
  },
  mounted() {
    const viewId = this.$route.query.viewId;

    if (viewId !== "all" && viewId !== "trashed") {
      this.getAllViews(parseInt(viewId, 10));
    } else {
      this.getAllViews(viewId);
    }
  },
  methods: {
    getConditions() {
      return this.conditions;
    },
    setConditions(conditions) {
      this.selectedFilterConditions = conditions;
      this.conditions = conditions;
      this.$refs.tabulatorDataTable.getFilterCondition(conditions);
    },
    apiGetBrowseUrl() {
      // Check if user have salesperson role, then assign only this salesperson can see back own sales.
      let browseUrl = `${this.$service[this.service.key]}/v1/en/console/${
        this.model.key
      }`;

      if (this.hrdcData.isSalespersonRoles && this.auth.roles.length == 1) {
        browseUrl = browseUrl + `?email=${this.auth.email}`;
      }

      return browseUrl;
    },
    apiGetBrowseTrashUrl() {
      let browseDeleteUrl = `
          ${this.$service[this.service.key]}/v1/en/console/${
        this.model.key
      }?deleted=true
        `;

      if (this.hrdcData.isSalespersonRoles && this.auth.roles.length == 1) {
        browseDeleteUrl = browseDeleteUrl + `&email=${this.auth.email}`;
      }

      return browseDeleteUrl;
    },
    setFiltersViaConditions(conditions) {
      this.$refs.filter.setFiltersViaConditions(conditions);
    },
    getCurrentPageSize(pagesize) {
      this.displayRowCount = pagesize;
    },
    getCurrentSort(sorters) {
      this.cColumnSort = sorters;
      this.cColumnSort = sorters.map((sorter) => {
        return {
          column: sorter.field,
          dir: sorter.dir,
        };
      });
    },
    getCurrentColumnWidth(resizedColumns) {
      const resizedColumnField = resizedColumns.getField();
      const resizedColumnWidth = resizedColumns.getWidth();

      //set the resized column width and filed name to the array
      const resizedColumnWidthAndField = {
        field: resizedColumnField,
        width: resizedColumnWidth,
      };

      const index = this.allResizeColumns.findIndex((item) => {
        return item.field === resizedColumnField;
      });

      if (index !== -1) {
        this.allResizeColumns[index].width = resizedColumnWidth;
      } else {
        this.allResizeColumns.push(resizedColumnWidthAndField);
      }
    },
    changeTableDetails(listAllViews, viewId) {
      // get the view details from listAllViews where id is equal to viewId
      if (viewId == "trashed") {
        this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseTrashUrl());
        this.$refs.selectColumn.showAllColumns([]);
        this.$refs.tabulatorDataTable.callSavedHeaderWidth([]);
        this.$refs.search.getFilterCondition([]); /// add this one
        this.$refs.customView.changeSelectedTabStatus("trashed", 0);
      } else if (viewId == "all") {
        this.$refs.tabulatorDataTable.callSavedHeaderWidth([]);
        this.$refs.selectColumn.showAllColumns([]);
        this.$refs.search.getFilterCondition([]);
        this.$refs.customView.changeSelectedTabStatus("all", 1);
        this.setCurrentPageTo();
      } else {
        const tabIndex =
          listAllViews.indexOf(listAllViews.find((x) => x.id === viewId)) + 2;
        this.$refs.customView.changeSelectedTabStatus(viewId, tabIndex);
        const singleViewDetail = listAllViews.filter((item) => {
          return item.id === viewId;
        });

        if (singleViewDetail.length > 0) {
          if (
            JSON.parse(singleViewDetail[0].manageColumnCustomWidth).length > 0
          ) {
            this.$refs.tabulatorDataTable.callSavedHeaderWidth(
              JSON.parse(singleViewDetail[0].manageColumnCustomWidth)
            );
          }

          this.$refs.selectColumn.showViewSelectedColumns(
            JSON.parse(singleViewDetail[0].cColumnCondition)
          );

          // calling API
          if (JSON.parse(singleViewDetail[0].filterCondition).length > 0) {
            this.$refs.filter.setFiltersViaConditions(
              JSON.parse(singleViewDetail[0].filterCondition)
            );
          }

          // calling API
          if (JSON.parse(singleViewDetail[0].cColumnSort).length > 0) {
            this.$refs.tabulatorDataTable.callSavedColumnSort(
              JSON.parse(singleViewDetail[0].cColumnSort)
            );
          }

          // calling API
          if (singleViewDetail[0].displayRowCount != this.paginationSize) {
            this.$refs.tabulatorDataTable.callSavedPageSize(
              singleViewDetail[0].displayRowCount
            );
          }
          // add following one
          this.$refs.search.getFilterCondition(
            JSON.parse(singleViewDetail[0].filterCondition)
          );
        }

        this.setCurrentPageTo();
      }
    },
    getAllViews(viewId) {
      this.api.setMethod(`GET`);
      this.api.setUrl(
        `${this.$service.crm}/v1/en/console/crmView/list?modelKey=${this.model.key}`
      );
      this.api.setCallbackCompleted((response) => {
        // get all private views when the ouwnerUuid is equal to auth.uuid
        const privateViews = response.filter((view) => {
          return view.isPrivate === true && view.ownerUuid === this.auth.uuid;
        });

        // get all public views
        const publicViews = response.filter((view) => {
          return view.isPrivate === false;
        });

        // push all private and public views to listAllViews
        this.listAllViews = [...publicViews, ...privateViews];
        this.changeTableDetails(this.listAllViews, viewId);
      });
      this.api.fetch();
    },
    applyColumnSelection(selectedColumns) {
      this.selectedCustomColums = selectedColumns;
      this.$refs.tabulatorDataTable.applyColumnSelection(selectedColumns);
    },
    getCurrentPageNumber(pageno) {
      this.currentPageNo = pageno;
    },
    setCurrentPageTo() {
      var pageNo =
        this.$store.state.developer.data.paginationPageNo != null
          ? this.$store.state.developer.data.paginationPageNo
          : 1;
      this.$refs.tabulatorDataTable.tabulator.setPage(pageNo);
      this.$store.commit("assignDeveloperData", {
        paginationPageNo: null,
      });
    },
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    showRefundClientForm(apSelectedDate) {
      this.isLoading = true;
      // check if any row is selected or not
      if (
        this.$refs.tabulatorDataTable.tabulator.getSelectedData().length == 0
      ) {
        this.isLoading = false;
        this.$store.dispatch("showMessage", {
          message: "Please select at least one row to download",
          messageType: "info",
          timeout: 2000,
        });
        return;
      }

      this.downloadExcelData(apSelectedDate);
    },
    async downloadExcelData(apSelectedDate) {
      let allRecords =
        this.$refs.tabulatorDataTable.tabulator.getSelectedData();

      let selectedRows = allRecords.map((row) => {
        return {
          Requestor: this.hrdcData.learnTaxPodEmail,
          Reference: row.creditNoteNo + "/" + row.autoGeneratedTaxpodInvoiceNo,
          Payee: this.getPayeeName(row.bankAccountHolderName),
          Paid: this.getPrice(row.price),
          "Bank Type": this.getBankName(row.bankName),
          "Account No.": this.getAccountNo(row.bankAccountNo),
        };
      });

      let selectedRecordsUuid = allRecords.map((row) => {
        return row.applicationUuid;
      });

      json2excel({
        data: selectedRows,
        name: "HRDC taxPOD Refund Details",
        formateDate: "yyyy/mm/dd",
      });
      this.isLoading = false;
      this.$refs.tabulatorDataTable.tabulator.deselectRow();
      this.updateCimbSubmitedDate(selectedRecordsUuid, apSelectedDate);
    },
    findBankType(bankName) {
      const options = {
        includeScore: true,
        shouldSort: true,
        isCaseSensitive: false,
        threshold: 0.3,
        // Search in `name`, bankType and in `abbreviation` array
        keys: ["name", "bankType", "abbreviation"],
      };

      let searchTextFormat = bankName
        .replace(/\b(?:bank|berhad|bhd|MALAYSIA)\b/gi, "")
        .replace(/[^a-zA-Z0-9]/g, "")
        .trim();

      const fuse = new Fuse(this.banks, options);

      const result = fuse.search(searchTextFormat);

      if (result.length == 0) {
        return bankName;
      } else {
        let bankType = result[0].item.bankType
          ? result[0].item.bankType
          : bankName;
        return bankType;
      }
    },
    getPayeeName(bankAccountHolderName) {
      return bankAccountHolderName.toUpperCase();
    },
    getAccountNo(bankAccNo) {
      return bankAccNo.replace(/[-\s]/g, "");
    },
    getBankName(bankName) {
      return this.findBankType(bankName);
    },
    getPrice(price) {
      let formatPrice = new Intl.NumberFormat("en", {
        minimumFractionDigits: 2,
      }).format(price);
      return formatPrice;
    },
    async updateCimbSubmitedDate(selectedRecordsUuid, cimbSubmitedDate) {
      this.loadingDialog = !this.loadingDialog;
      let data = {
        selectedApplicationsUuids: selectedRecordsUuid,
        financeRefundToClientDate: cimbSubmitedDate,
        referById: this.auth.uuid,
        editMode: this.editMode,
      };

      this.apiUpdateApSubmitRefundToCimb.setMethod(`POST`);
      this.apiUpdateApSubmitRefundToCimb.setUrl(
        `${
          this.$service[this.service.key]
        }/v1/en/console/finance_refund/create-finance-refund-bulk`
      );

      this.apiUpdateApSubmitRefundToCimb.setParams(data);
      this.apiUpdateApSubmitRefundToCimb.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.loadingDialog = !this.loadingDialog;
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }
          }

          if (response.status) {
            this.toggleSelectionDialog(false);
            this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseUrl());
            this.getAllViews("all");
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiUpdateApSubmitRefundToCimb.fetch();
    },
    closeRejectDialog() {
      this.clearErrorMsg();
      this.rejectDialog = false;
    },
    toggleSelectionDialog(val) {
      this.apApplicationSelectionDialog = val;
    },
    rejectApplicationToAR(formData) {
      this.showLoadingDialog();

      this.clearErrorMsg();
      this.apiRejectApplicationToAR.setMethod("post");
      this.apiRejectApplicationToAR.setParams({
        ...formData,
        revertBackToStageId: this.hrdcData.stagePriority.refundEmailApToCimbRejected,
        refundEmailApToCimbRejectedStageId:
          this.hrdcData.stagePriority.refundEmailApToCimbRejected,
        userUuid: this.auth.uuid,
      });
      this.apiRejectApplicationToAR.setUrl(
        `${
          this.$service[this.service.key]
        }/v1/en/console/finance_refund/reject-application-to-ar`
      );
      this.apiRejectApplicationToAR.setCallbackCompleted((response) => {
        try {
          if (!response.status) {
            this.showErrorMessage(response);
            this.hideLoadingDialog();
          }

          if (response.status) {
            this.closeRejectDialog();
            this.formData.rejectReason = null;

            this.$router.go();
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });

            this.hideLoadingDialog();
          }
        } catch (err) {
          console.log(err);
          this.alertError.push(err.message);
          return false;
        }
      });
      this.apiRejectApplicationToAR.fetch();
    },
  },
};
</script>
